@use 'src/variables';
@use 'src/scrollbars';

.tableContainer {
    font-size: 0.875em;
    overflow-x: scroll;
    padding-bottom: calc(variables.$gutter / 2);

    @include scrollbars.theme-scrollbar;

    @media (min-width: 420px) {
        padding-bottom: variables.$gutter;
    }

    @media (min-width: 680px) {
        overflow-x: auto;
        padding-bottom: 0;
    }

    @media (min-width: 960px) {
        font-size: 1em;
    }
}

.payslipTable {
    width: 100%;
    margin: auto;
}

th.tableHeadings {
    padding: 15px 5px 2px;
    width: 15%;

    @media (min-width: 600px) {
        width: 15%;
    }

    &:nth-child(1) {
        width: 5%;
    }

    &:nth-child(2) {
        width: 50%;
    }
}

.cellDate, .cellDeductions, .cellGross, .cellNet {
    width: auto,
}

.cellDeductions, .cellGross, .cellNet {
    text-align: right;
}

.downArrow {
    margin: 0 0 -7px 5px;
    fill: #858585
}

.selectedCount{
    display: flex;
    justify-content: center;
    border-radius: 5px;
    max-width: 100px;
}

.button, .bulkDownloadButton {
    background: none;
    border: none;
    cursor: pointer;

    text-transform: uppercase;
    font-weight: 700;
    color: #666;

    @media (max-width: 600px) {
        font-size: 14px;
    }

    .icon {
        display: none;
        fill: #bbb;

        @media (min-width: 600px) {
            display: inline;
            margin-left: 8px;
        }
    }

    &:hover {
        .underlineFill {
            width: 100%;
        }
    }

    .text {
        font-size: 1em;
        font-family: variables.$base-font-family;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 2px;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.button { 
    padding-right: 14px;
}

.bulkActions {
    width: auto;
    display: flex;
}

.downloadButton {
    float: right;
}

.downloadCard {
    width: 370px;
    border-radius: 6px;
    box-shadow: -3px 1px 20px rgba($color: #000000, $alpha: 0.51);
    transform: translateY(-10px) translateX(275px);
    padding: 0px;

    @media (max-width: 600px) {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        border-radius: 0px;

        transform: translateY(0px);
    }
}

.passwordDescription {
    margin: 0px 10px 10px 10px;
    font-size: 14px;
    font-family: variables.$base-font-family;
    font-weight: 500;
    line-height: 18px;
    color: #868686;
}

.input {
    margin: 0 0 -10px 10px;
    width: 94%;
    box-sizing: border-box;
    display: block;
    background: #eee;
    border: solid 1px #878787;
    border-radius: 2px;
    max-width: 400px;
    padding: 8px;
    color: #666;
    font-size: 1.1em;
    transition: box-shadow ease 300ms, border-color ease 300ms, -webkit-box-shadow ease 300ms;
    overflow: visible;

    &:focus {
        border-color: #878787;
        box-shadow: 0px 0px 4px 0px #1592E6;
    }
}

.bulkSelected {
    width: 125px;
}

.bulkDownloadButton {
    margin: 25px 10px 0 10px;
}

.arrowIconClasses {
    color: #858585;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 15px;
    left: 25px;

    &:hover {
        cursor: pointer;
    }
}

.drawer {
    z-index: 101;

    @media (max-width: 640px) {
        width: 320px;
        top: 0;
        right: 0;
    }
}

.drawerFormContent {
    padding: 90px 75px 25px 75px;

    @media (max-width: 980px) {
        padding: 90px 25px 25px 25px;
    }

    > :first-child {
        margin-bottom: 55px;
    }

    > :last-child {
        margin-top: 55px;
        font-size: 14px;
        
        > button {
            padding: 0;
        }
    }
}
