@use "../../variables";

.headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.title {
    margin-bottom: 0px;
    font-size: variables.$heading-font-size;
}

.payslipContainer {
    & + & {
        margin-top: 50px;
    }

    margin-bottom: 75px;
}

.loadingSpinner {
    width: 250px;
    margin: 0 auto;
}

.dateCutout {
    box-sizing: content-box;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background-color: #eee;
    box-shadow: inset 0 -15px 4px -5px #e7e7e7;

    @media(min-width: 600px) {
        width: 90px;
        height: 90px;
    }
}

.dateLabel, .dateBadge {
    box-sizing: content-box;
    position: absolute;
    top: 0px;
    border-radius: 50%;    
    width: 60px;
    height: 60px;

    @media(min-width: 600px) {
        width: 70px;
        height: 70px;
    }
}

.dateBadge {
    top: 10px;
    right: 10px;
    align-content: center;
    box-shadow: 0 0 4px 2px #e7e7e7;
}

.loadingCard, .loadingHeader {
    width: 800px;
}

.loadingCard{
    height: 227px;
}

.loadingHeader{
    height: 60px;
}

.buttonContainer {
    margin-top: 8px;

    @media (min-width: 600px) {
        margin-top: 12px;
    }
}
