.donutChart {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 2px 20px 0;
    cursor: pointer;

    @media (min-width: 600px) {
        padding: 5px 20px 0;
    }
}
