@use "src/variables";

.keyInfo {
    min-width: 25%;
}

.keyInfoHeader {
    display: flex;
}

.keyInfoTitleBox {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 25px 0 0 0;
    left: 100px;
    bottom: 75px;
    width: 50%;
}

.keyInfoTitle {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
}

.KeyInfoDepartment {
    color: #767676;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: bold;
    font-size: 14px;
    font-family: variables.$base-font-family;
    margin: 5px 0 25px;
}

.profileIcon {
    display: flex;
    margin: 25px 25px 35px 25px;
    height: 50px;
    width: 50px;
}

.listContainer {
    margin: 0 25px;
}
