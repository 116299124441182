.button {
    background: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    color: #666;
    text-decoration: none;
    margin-right: 20px;

    &:hover {
        .underlineFill {
            width: 100%;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }
}
