@use "src/variables";

.myDetails {
    min-width: 25%;
}

.myDetailsHeader {
    display: flex;
    border-bottom: 1px solid #dddddd;
}

.myDetailsTitleBox {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 25px 0 0 0;
    left: 100px;
    bottom: 75px;
    width: 50%;
}

.myDetailsTitle {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
}

.myDetailsDepartment {
    color: #767676;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: bold;
    font-size: 14px;
    font-family: variables.$base-font-family;
    margin: 5px 0 25px;
}

.profileIcon {
    display: flex;
    margin: 25px 25px 35px 25px;
    height: 50px;
    width: 50px;
}

.bodyContent {
    margin: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.infoSection, .hmrcSection {
    color: #757575;
    font-size: 14px;
    line-height: 25px;
}

.infoSection {
    margin: 50px 0 25px;
}

.hmrcSection {
    margin: 0 0 25px;
}
