@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'openSans';
    src: url('./font/OpenSans/OpenSans-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
}
