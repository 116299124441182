$iconSize: 20px;

.newCount {
    height: $iconSize;
    min-width: $iconSize;
    border: 1px solid #eee;
    border-radius: calc($iconSize / 5);
    box-sizing: border-box;
    padding: 0px 2px;
    font-size: calc(3 * $iconSize / 4);
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}