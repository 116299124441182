@use "src/variables";

.skipLink {
    position: absolute;
    margin: variables.$gutter;
    background: #32A1EC;
    color: #000000;
    padding: 15px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 102;
    pointer-events: none;

    &:focus {
        opacity: 1;
        pointer-events: unset;
    }
}
