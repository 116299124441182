@use './variables';

body {
    color: variables.$base-font-color;
    font-size: variables.$base-font-size;
    font-family: variables.$base-font-family;
    line-height: variables.$base-line-height;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    margin: 0 0 0.75em;
    color: variables.$heading-font-color;
    font-weight: variables.$heading-font-weight;
    font-family: variables.$heading-font-family;
    line-height: variables.$heading-line-height;
}

h1, .h1 { font-size: 1.5em; }
h2, .h2 { font-size: 1.3em; }
h3, .h3 { font-size: 1.125em; }
h4, .h4,
h5, .h5,
h6, .h6 { font-size: variables.$base-font-size; }

@media (min-width: 960px) {
    h1, .h1 { font-size: 2em; }
    h2, .h2 { font-size: 1.5em; }
    h3, .h3 { font-size: 1.17em; }
}
