@use "../../variables";

.headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.title {
    margin-bottom: 0px;
    font-size: variables.$heading-font-size;
}

.payslipContainer {
    & + & {
        margin-top: 50px;
    }

    margin-bottom: 75px;
}

.viewMore {
    font-family: variables.$base-font-family;
}

.yearSelect {
    cursor: pointer;
    border-radius: 4px;
}

.header {
    margin-bottom: 105px;
}

.downloadContainer {
    height: 60px;
}

.roundedIcon {
    margin: 20px 10px 0;
    position: relative;
    cursor: pointer;
    padding: 3px 0 0 0;
    border-radius: 25px;
    top: -25px;
    float: right;
    height: 45px;
    width: 45px;
    background-color: transparent;
    border: solid 2px variables.$accent-primary;

    @media (min-width: 600px) {
        margin-top: 25px;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }

    &:hover {
        border: variables.$input-border-hover;
    }
}

.pageHeaderRight {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dateInput {
    width: 160px;
}

.buttonMenu {
    min-width: auto;
    border-radius: 6px;
    box-shadow: -3px 1px 20px rgba($color: #000000, $alpha: 0.51);
    transform: translateY(-10px) translateX(10px);
    padding: 10px 16px;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.dateFilterBody {
    margin-bottom: 10px;
}

.dateInputContainer {
    &:last-child {
        margin: 0 0 0 20px;
    }
}

.dateInputTitle, .dateFilterTitle {
    color: #757575;
    font-weight: 600;
}

.dateFilterContent {
    display: flex;
    margin-bottom: 20px;
}

.dateFilterTitle {
    margin-bottom: 20px;
}

.emptyText {
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin-top: 150px;
}

.updateButton {
    margin-right: 20px;
}
