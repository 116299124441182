@use "src/helpers";
@use "src/variables";

$footerContainerHeight: 275px;

.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 46px;
}

.footerSection {
    position: relative;
    width: 100%;
    margin-bottom: 30px;

    @media (min-width: 600px) {
        width: calc(50% - 30px);
    }
}

.keyInfoContainer {
    height: $footerContainerHeight;
    margin-bottom: 10px;

    @media (min-width: 600px) {
        margin-bottom: 0;
    }
}

.notificationsContainer {
    height: $footerContainerHeight;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    > *:not(:last-child) {
        margin-bottom: calc((#{$footerContainerHeight} - 240px) / 2)
    }
}

.notificationsActions {
    margin-top: 10px;
}

.payslipSection {
    margin-bottom: 20px;

    @media (min-width: 600px) {
        margin-bottom: 35px;
    }
}

.sectionHeader {
    line-height: 20px;
    font-size: variables.$heading-font-size;
    margin-bottom: 20px;

    @media (min-width: 600px) {
        line-height: 25px;
        margin-bottom: 15px;
    }
}