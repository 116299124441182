@use "src/variables";

.toggleContainer {
    margin-bottom: 20px;
}

.text {
    padding: 10px 0;

    &.hidden {
        display: none;
    }
}

.arrowOpen, .arrowClosed {
    position: relative;
    top: 7px;
    right: 0px;
    fill: variables.$accent-primary;
    height: 25px;
    width: 25px;
    transition: transform ease variables.$ui-animation-duration;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.arrowOpen {
    transform: rotate(90deg);
}
