.notificationsButton {
    position: relative;
    margin-right: 25px;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.newCountAnimation {
    animation: newCount 0.5s ease-in-out 4.3s 1 normal,reverse both running;
    animation-timing-function: cubic-bezier(0.57, 0.26, 1, 0.54);
    backface-visibility: hidden;
}

@keyframes newCount {
    0% {
        transform: translate(0%, 0px);
    }
    20% {
        transform: translate(0%, -3px);
    }
    23% {
        transform: translate(0%, -3px);
    }
    60% {
        transform: translate(0%, 0px)
    }
    100% {
        transform: translate(0%, 0px);
    }
}

.newCount {
    position: absolute;
    bottom: calc(50% - 5px);
    left: calc(50% - 2.5px);
}
