@use "../variables";

.contentContainer {
    position: relative;
    
    @media (min-width: variables.$breakpoint-hidden-menu) {
        margin-left: variables.$menu-width;
    }
}

.contentWrapper {
    margin: 55px 20px 0px;
    padding-bottom: 90px;

    &.hasGutter {
        @media (min-width: 840px) {
            margin: 35px auto 0px;
        }
    }

    @media (min-width: 600px) {
        margin: 35px 20px 0px;
    }
}

.loginWrapper {
    margin: 55px 0px 90px;

    @media (min-width: 600px) {
        margin: 35px auto 90px;
    }
}