@mixin keyframe-animation($steps, $name: unique-id()) {
    $total-time: 0ms;

    @each $step in $steps {
        $total-time: $total-time + map-get($step, delay);
    }

    animation-duration: $total-time;
    animation-name: $name;
    animation-fill-mode: forwards;

    @keyframes #{$name} {
        $offset: 0ms;

        @each $step in $steps {
            $offset: $offset + map-get($step, delay);
            $percentage: calc($offset / $total-time) * 100%;

            #{$percentage} {
                @each $property, $value in map-get($step, styles) {
                    #{$property}: #{$value};
                }
            }
        }
    }
}

@mixin border-triangle($direction, $color, $width, $height) {
    width: 0px;
    height: 0px;

    $main-border: solid $height $color;
    $side-borders: solid calc($width / 2) transparent;

    border-#{opposite-direction($direction)}: $main-border;

    @if $direction == top or $direction == bottom {
        border-left: $side-borders;
        border-right: $side-borders;
    } @else if $direction == right or $direction == left {
        border-top: $side-borders;
        border-bottom: $side-borders;
    }
}
