@use "src/variables";

.button {
    fill: variables.$accent-primary;
    background: none;
    border: 0;
    padding: 0;
    margin-top: 10px;
    height: 25px;
    width: 25px;
    cursor: pointer;

    @media (min-width: 600px) {
        margin-top: 15px;
    }

    &.wide {
        padding-right: 15px;
    }
}

.arrowIcon {
    position: absolute;
    top: calc(50% - 12px);
    right: -18px;
    fill: variables.$accent-primary;
    height: 25px;
    width: 25px;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}
