@use "../../../variables";

.inputRow {
    margin: variables.$gutter 0 0;

    @media (min-width: 960px) {
        display: flex;
        max-width: 810px;
    }
}

.inputContainer {
    @media (min-width: 960px) {
        flex: 0 0 calc(50% - #{calc(variables.$gutter / 2)});
        margin: 0 variables.$gutter 0 0;
    }

    &:last-child {
        margin: calc(variables.$gutter / 2) 0 0 0;

        @media (min-width: 960px) {
            margin: 0;
        }
    }
}
