.listContent {
    display: flex;
    flex-direction: column;
    max-width: 460px;
}

.termList {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.descriptionList {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.description {
    color: #444;
    margin-top: 2;
    font-size: 15;
    padding-left: 25px;
};

.term {
    margin-top: 2;
    font-size: 13;
    color: #757575;
};

.rowContainer {
    display: flex;
    flex-direction: row;
};
