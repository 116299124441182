@use "../../../variables";

.buttonContainer {
    margin: variables.$gutter 0 0;

    @media (min-width: 960px) {
        margin: calc(variables.$gutter * 1.5) 0 0;
    }
}

.primaryButtonContainer,
.secondaryButtonContainer {
    display: inline-block;
}

.secondaryButtonContainer {
    margin: calc(variables.$gutter / 2) 0 0 calc(variables.$gutter / 2);

    @media (min-width: 600px) {
        margin: 0 0 0 variables.$gutter;
    }
}
