@use "src/variables";
@use 'src/helpers';

.container {
    position: relative;
    background: transparent;
    display: flex;
    flex-wrap: wrap;
    height: 40px;

    @media (min-width: 600px) {
        height: 60px;
    }

    @media (min-width: variables.$breakpoint-hidden-menu) {
        padding-left: variables.$menu-width;
    }
}

.barLoaded {
    animation-delay: 1900ms;
    transform: translateY(-100%);

    @include helpers.keyframe-animation((
        ( delay: 0ms, styles: ( transform: translateY(-100%) ) ),
        ( delay: 300ms, styles: ( transform: translateY(0%) ) ),
    ));
}

.buttonWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 45px;
    height: 45px;
    margin: 0px 0px 0px 7.5px;

    @media (min-width: 600px) {
        margin: 5px 0px 0px 10px;
    }

    @media (min-width: variables.$breakpoint-hidden-menu) {
        left: 280px;
        position: absolute;
    }
}

.headerIconMenus {
    display: flex;
    order: 1;
    flex: 1 1 auto;
    justify-content: flex-end;
    max-height: 60px;

    @media (min-width: 768px) {
        order: 2;
    }

    > button {
        border: 0;
        background: none;
        padding: 0;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.iconSize {
    width: 24px;
    height: 24px;

    @media (min-width: 600px) {
        width: 30px;
        height: 30px;
    }
}