@use 'src/variables';

.notificationsMenu {
    width: 370px;
    border-radius: 6px;
    box-shadow: -3px 1px 20px rgba($color: #000000, $alpha: 0.51);
    transform: translateY(-10px);
    padding: 0px;

    @media (max-width: 600px) {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        border-radius: 0px;

        transform: translateY(0px);
    }
}

.notificationModal {
    font-family: variables.$base-font-family;
    font-weight: bold;
    font-size: 15px;
    color: #757575;
    padding-left: 10px;
    display: flex;
    padding-top: 25px;
}

.notificationContainer {
    height: 75px;
    border: 0px;
}
