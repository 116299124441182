.buttonMenu {
    min-width: auto;
    border-radius: 6px;
    box-shadow: -3px 1px 20px rgba($color: #000000, $alpha: 0.51);
    transform: translateY(-10px) translateX(10px);
    padding: 10px 16px;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.action {
    width: 100%;
    max-width: 100%;
    text-align: left;
    cursor: pointer;
    font-size: 1em;
    text-transform: capitalize;
    color: #333;
    padding: 11px 6px;
    border-radius: 6px;

    &:last-of-type {
        margin-bottom: 0;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }

    &:hover {
        transition: background 0.3s ease;
        background-color: #efefef;
    }
}