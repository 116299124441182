$graphicWidth: 148px;

.header {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;

    @media (max-width: 500px) {
        font-size: 17px;
        font-weight: 700;
        padding-right: 0px;
    }
}

.body {
    position: relative;
    padding-top: 15px;
    padding-right: $graphicWidth;

    @media (max-width: 500px) {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: $graphicWidth;
    }
}

.graphic {
    position: absolute;
    top: 0px;
    right: 0px;
    width: $graphicWidth;

    @media (max-width: 500px) {
        width: unset;
        height: 150px;
        top: unset;
        right: unset;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
}
