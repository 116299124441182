.button {
    position: relative;
    padding: 0px;
    margin-right: 25px;
    border: none;
    background: none;
    cursor: pointer;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}