@use "node_modules/@dataplan/react-components/dist/helpers";
@use "src/variables";
@import "~sassdash/index";
@import "~sass-svg/index";

.pageHeader {
    display: inline-block;

    .searchBox {
        overflow: auto;
        margin: 80px 0 20px 0;

        @media (min-width: 600px) {
            margin: -10px 0 0 0;
        }
    }
}

.headerContainer {
    display: flex;
}

.selectors {
    width: 50%;
    min-width: 172px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;

    @media (min-width: 600px) {
        flex-direction: row-reverse;
        align-items: flex-end;
        align-content: flex-end;
    }
}

.tabs {
    width: 50%;
}

.filter {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-content: flex-end;
}

.accordionList {
    cursor: pointer;

    :first-child {
        border-radius: 6px 6px 0px 0px;
    }

    :last-child {
        border-radius: 0px 0px 6px 6px;
        overflow: hidden;
    }

    & > :only-child {
        border-radius: 6px;
        overflow: hidden;
    }
}

.subTitle {
    font-family: variables.$base-font-family;
    color: #757575;
    font-weight: unset;
    font-size: 14px;
    padding: 0px 40px 0px 20px;
    text-align: start;

    @media (min-width: 600px) {
        font-size: 15px;

        padding: 0px 50px 0px 25px;
    }

    @include helpers.line-clamp(1, 25px);
}

.groupSelect {
    cursor: pointer;
    border-radius: 4px;
    width: unset;
    display: inline-block;
    margin-left: 12px;
    margin-bottom: 12px;

    @media (min-width: 600px) {
        margin-bottom: 0;
    }
}

.pageContent {
    margin-top: 70px;

    @media (min-width: 600px) {
        margin-top: 0;
    }
}
