.menuItem {
    white-space: nowrap;
    height:45px;
    display: flex;
    font-size: 14px;
}

.menuLink {
    display: inline-block;
    margin: 7.5px;
    text-decoration: none;
    font-weight: 500;

    &:hover, &:focus {
        .underlineFill {
            width: 100%;
        }
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.underline {
    background: transparent;
    margin-top: 2px;
}

.branded {
    .underlineText {
        opacity: 0.75;
    }

    &:hover, &.brandedActive {
        .underlineText {
            opacity: 1;
        }
    }
}

.unbranded {
    .underlineText {
        color: #767676;
    }

    &:hover, &.unbrandedActive {
        .underlineText {
            color: #111;
        }
    }
}

.active {
    .underlineFill {
        width: 100%;
    }
}

.newIcon {
    border-radius: 4px;
    height: 22px;
    min-width: 22px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0px;
}
