@use '../variables';

.container {
    max-height: inherit;
    overflow: auto;
    box-sizing: border-box;

    &.includeNav {
        padding-bottom: 50px;
    }
}

.nav {
    position: absolute;
    height: 50px;
    width: 100%;
    bottom: -1px;
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: 0px 0px 6px 6px;
}

.pageNumbers {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 35px;
    max-width: 100%;
    display: flex;
    overflow: hidden;
}

.pageNumber {
    margin: 5px;
    border: none;
    background-color: variables.$accent-primary;
    color: black;
    cursor: pointer;
    min-height: 25px;
    min-width: 25px;
    padding: 0px;
    text-align: center;

    &.disabled {
        box-sizing: border-box;
        border: 1px solid black;
        background-color: rgba(255, 255, 255, 0.33);
    }
}
