@use 'src/variables';

.payslipCover {
    width: auto;
    height: 200px;
    margin: -1px 0 0 0;
    border-radius: 0 0 6px 6px;

    @media (min-width: 600px) {
        height: 225px;
    }
}

.hover {
    cursor: pointer;

    &:hover {
        .underlineFill {
            width: 100%;
        }
    }
}

.payslipAlert {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
    font-size: 32px;
    font-weight: 400;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 38px;

    @media (min-width: 600px) {
        font-size: 40px;
        padding-top: 50px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.viewPayslip {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 66px;
    font-size: 15px;
    font-weight: 500;
    font-family: variables.$base-font-family;
    width: 100%;

    @media (min-width: 600px) {
        padding-top: 69px;
    }
}

.viewPayButton {
    padding: 0;
    border: none;
    color: inherit;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}