.header {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;

    @media (max-width: 500px) {
        font-size: 17px;
        font-weight: 700;
        padding-right: 0px;
    }
}

.body {
    margin-bottom: 60px;
    font-size: 16px;
    display: flex;
    flex-direction: column;

    > span {
        margin: 15px 0;
    }

    @media (max-width: 500px) {
        font-size: 11px;
        padding-right: 0px;
    }
}

.badge {
    margin-top: 20px;
    width: 160px;
    height: 60px;
}

.qrCode {
    width: 100px;
    height: 100px;
    margin: 0 20px;
}

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
}

.badgeQRContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.textContainer {
    display: flex;
}

.startButton {
    text-transform: unset;
    width: 200px;
    font-size: 16px;
    margin-top: 20px;

    @media (max-width: 767px) {
        align-self: center;
    }
}
