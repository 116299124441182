@use "../../variables";

$iconSizeMobile: 20px;
$iconSizeDesktop: 25px;

.externalLink {
    display: inline-flex;
    font-family: variables.$base-font-family;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 75%;
    padding: 10px 30px 10px 0px;
}

.icon {
    margin-top: -3px;
    margin-left: -8px;
    fill: variables.$accent-primary;
    width: $iconSizeMobile;
    height: $iconSizeMobile;
    transition: transform 0.5s;

    @media (min-width: 600px) {
        width: $iconSizeDesktop;
        height: $iconSizeDesktop;
    }
}

.open {
    transform: rotate(-90deg)
}

.dropdownHeader {
    margin-top: 25px;
    display: inline-flex;
    font-family: variables.$base-font-family;
    font-weight: 500;
    text-decoration: none;
    font-size: 75%;
    color: #333;
    cursor: pointer;
}

.header {
    color: #868686;
    font-weight: 500;
    display: flex;
    font-family: variables.$base-font-family;
    font-size: 14px;
}

.button {
    margin: variables.$gutter 0 0;

    @media (min-width: 960px) {
        margin: calc(variables.$gutter * 1.5) 0 0;
    }
}

.error {
    border: 1px solid variables.$error-color;

    &:focus {
        border-color: variables.$error-color;
        box-shadow: 0 0 4px 0 variables.$error-color;
    }
}

.linebreak {
    margin-bottom: 10px;
}

.QRcontainer {
    max-width: 250px;
    max-height: 250px;
    margin-bottom: -25px;
}

.textInput {
    width: 100%;
    max-width: 200px;
}

.code {
    margin-top: 10px;
    font-family: variables.$base-font-family;
    color: #555;
    margin-bottom: -25px;
}
