@use "src/variables";

.list {
    list-style: none;
    padding-left: variables.$gutter;
}

.listItem {
    position: relative;
    margin-bottom: calc(variables.$gutter / 3);

    &::before {
        content: "\2022";
        position: absolute;
        left: -20px;
        top: -2px;
        color: #e2e2e2;
        font-size: 1.125em;
        font-weight: bold;
    }

    a {
        color: variables.$accent-secondary;
        text-decoration: none;
    }
}

.buttonContainer {
    display: flex;
    flex-direction: row;
}
