@use './scrollbars';
@use './variables';
@use './typography';
@use './fonts';

html {
    height: 100%;
    @include scrollbars.theme-scrollbar;
    min-width: 300px;
}

body {
    min-height: 100%;
    background: #eee;
    font-family: variables.$base-font-family;
    font-size: 16px;
    color: #333;
    overflow-y: scroll;
}

* {
    :global(.__flag-user-is-not-tabbing) & {
        outline: none;
    }

    :global(.__flag-user-is-not-tabbing) &:-moz-focusring {
        outline: none;
    }
}
