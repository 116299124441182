@use 'src/variables';

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #ddd;
    box-sizing: border-box;
    border-radius: 6px;
    height: 80px;
    width: 100%;
    box-shadow: -3px 1px 6px #ddd;

    &:nth-child(2) {
        opacity: 70%;
    }

    &:nth-child(3) {
        opacity: 30%;
    }   
}

.notification {
    text-align: center;
    margin: auto;
    font-family: variables.$base-font-family;
    font-weight: "bold";
    font-size: 16px;
}
