$graphicWidth: 180px;

.header {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;

    @media (max-width: 500px) {
        font-size: 17px;
        font-weight: 700;
        padding-right: 0px;
    }
}

.body {
    position: relative;
    padding-right: $graphicWidth;
    font-size: 16px;
    display: flex;
    flex-direction: column;

    > span {
        margin: 15px 0;
    }

    @media (max-width: 500px) {
        font-size: 11px;
        padding-right: 0px;
        padding-bottom: $graphicWidth;
    }
}

.graphic {
    position: absolute;
    top: 50%;
    right: 0px;
    width: $graphicWidth;
    transform: translateY(-50%);

    @media (max-width: 500px) {
        width: unset;
        height: 150px;
        top: unset;
        right: unset;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.startButton {
    text-transform: unset;
    width: 200px;
    font-size: 16px;
    margin-top: 79px;

    @media (max-width: 767px) {
        margin-top: 20px;
        align-self: center;
    }
}