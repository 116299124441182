.header {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;

    @media (max-width: 500px) {
        font-size: 17px;
        font-weight: 700;
        padding-right: 0px;
    }
}

.body {
    position: relative;
    font-size: 16px;
    flex-direction: column;

    > span {
        margin: 15px 0;
    }

    @media (max-width: 500px) {
        font-size: 11px;
    }
}

.footer {
    position: relative;
    padding-left: 65px;
    height: 50px;
    margin-top: 49px;
}

.footerText {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 17px;
    font-weight: 700;

    @media (max-width: 500px) {
        font-size: 14px;
        font-weight: 700;
    }
}

.helpGraphic {
    position: absolute;
    left: 0px;
    width: 50px;
    height: 50px;
}

.startButton {
    text-transform: unset;
    width: 200px;
    font-size: 16px;
    margin-top: 61.61px;

    @media (max-width: 767px) {
        margin-top: 30px;
        align-self: center;
    }
}