@use 'src/variables';

.staticWrapper {
    cursor: pointer;
}

.dateCutout {
    box-sizing: content-box;
    position: absolute;
    align-content: center;
    top: 0px;
    left: 50%;
    border-radius: 50%;
    height: 76px;
    width: 76px;
    background-color: #eee;
    box-shadow: inset 0 -15px 4px -5px #ccc;
    transform: translate(-50%, -50%);

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        box-shadow: none;
    }

    @media(min-width: 600px) {
        width: 90px;
        height: 90px;
    }
}

.dateLabel, .dateBadge {
    box-sizing: content-box;
    position: absolute;
    top: 0px;
    border-radius: 50%;
    width: 60px;
    height: 60px;

    @media(min-width: 600px) {
        width: 70px;
        height: 70px;
    }
}

.dateBadge {
    top: 8px;
    right: 8px;
    align-content: center;
    box-shadow: 0 0 4px 2px #ccc;

    @media(min-width: 600px) {
        top: 10px;
        right: 10px;
    }
}

.loadingSpinner {
    width: 250px;
    margin: 0 auto;
    padding: 83.5px 0px;
}

.wobbleAnimation {
    animation: wobble 0.5s ease-in-out 0.077s 1 normal,reverse both running;
    animation-timing-function: cubic-bezier(0.57, 0.26, 1, 0.54);
    backface-visibility: hidden;
}

@keyframes wobble {
    0% {
        transform: translate(0%, 0px);
    }
    20% {
        transform: translate(0%, -8px);
    }
    23% {
        transform: translate(0%, -8px);
    }
    60% {
        transform: translate(0%, 0px);
    }
    100% {
        transform: translate(0%, 0px);
    }
}

.secondaryCard {
    margin-top: 35px;
}

.accordionList {
    cursor: pointer;

    &.borderRadius {
        border-radius: 6px;

        :first-child {
            border-radius: 6px 6px 0px 0px;
        }

        :last-child {
            border-radius: 0px 0px 6px 6px;
        }
    }
}

.listContainer {
    margin: 0px;
    padding: 0px 20px 45px;

    @media (min-width: 600px) {
        padding: 0px 100px 45px;
    }
}

.elementLine {
    overflow: auto;
    border-top: solid 1px #e6e6e6;
    height: 45px;
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;

    align-items: center;

    &:first-child {
        border-top: none;
    }
}

.listKey, .listValue {
    color:#666666;
    font-family: variables.$base-font-family;
}

.listKey {
    float: left;
    font-size: 13px;
    font-weight: 500;

    @media (min-width: 600px) {
        font-size: 15px;
    }
}

.listValue {
    float: right;
    font-size: 15px;

    @media (min-width: 600px) {
        font-size: 17px;
    }
}

.label {
    margin: 0px;
    font-family: variables.$base-font-family;
    color: #757575;
    font-weight: unset;
    transition-delay: 0.1875s;
    transition-duration: 0.375s;

    &.highlighted {
        transition-delay: 0s;
        color: #333;
    }

    &.primary {
        font-size: 14px;

        @media (min-width: 600px) {
            font-size: 15px;
        }

        &.highlighted {
            font-size: 17.5px;

            @media (min-width: 600px) {
                font-size: 18.75px;
            }
        }
    }

    &.secondary {
        font-size: 13px;

        @media (min-width: 600px) {
            font-size: 13.6px;
        }

        &.highlighted {
            font-size: 16.25px;

            @media (min-width: 600px) {
                font-size: 17px;
            }
        }
    }
}

.value {
    margin: 0px;
    font-size: 17px;
    color: #333;
    font-weight: unset;

    @media (min-width: 600px) {
        font-size: 20px;
    }

    &.net {
        font-size: 26px;

        @media (min-width: 600px) {
            font-size: 28px;
        }
    }
}

.valueWithoutArrows {
    margin: 0px -20px 0px 0px;
    font-size: 17px;
    color: #333;
    font-weight: unset;

    @media (min-width: 600px) {
        font-size: 20px;
    }

    &.net {
        font-size: 26px;

        @media (min-width: 600px) {
            margin: 0px -25px 0px 0px;
            font-size: 28px;
        }
    }
}

.header {
    width: 100%;
    height: 100%;
    padding: 0px 40px 0px 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    @media (min-width: 600px) {
        padding: 0px 50px 0px 25px;
    }

    &.net {
        height: 75px;
    }

    &.static {
        height: 76px;
        border-bottom: solid 1px #e6e6e6;
    }
}
