.title {
    font-size: 16px;
    font-weight: 500;
    color: #555;
    margin: 25px 0;
}

.text {
    padding: 10px 20px 15px 20px;
    font-weight: 500;
}

.card {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.videoContainer {
    padding-bottom: 20px;
}
