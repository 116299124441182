.payslipDefault {
    transition: opacity 0.6s, transform 0.3s ease;
    opacity: 0;
    transform: translateY(5vh);
    backface-visibility: hidden;
};

.payslipDefaultEntering {
    opacity: 0;
    transform: translateY(5vh);
}

.payslipDefaultEntered {
    opacity: 1.0; 
    transform: translateY(0vh);
}

.payslipDefaultAppearing {
    opacity: 0;
}

.payslipDefaultAppeared {
    opacity: 0;
}

.payslipDefaultLeftExiting {
    opacity: 0; 
    transform: translateX(100vw); 
    transition-timing-function: ease-in-out;
};

.payslipDefaultLeftExited {
    opacity: 0;
}

.payslipDefaultRightExiting {
    opacity: 0; 
    transform: translateX(-100vw); 
    transition-timing-function: ease-in-out;
};

.payslipDefaultRightExited {
    opacity: 0;
}

.payslipLast {
    opacity: 1;
    backface-visibility: hidden;
    will-change: transform;
};

.payslipLastRightEntering {
    opacity: 1;
}

.payslipLastRightExiting {
    opacity: 1.0; 
    animation: last 0.5s 0s;
    animation-timing-function: cubic-bezier(0.57, 0.26, 1, 0.54);
}

@keyframes last {
    0% {
        transform: translateX(0vw);
    }
    30% {
        transform: translateX(-10vw);
    }
    60% {
        transform: translateX(0vw);
    }
    70% {
        transform: translateX(-3px);
    }
    80% {
        transform: translateX(0vw);
    }
}

.payslipFirst {
    opacity: 1;
    backface-visibility: hidden;
};


.payslipFirstLeftEntering {
    opacity: 1;
}

.payslipFirstLeftExiting {
    opacity: 1.0; 
    animation-timing-function: cubic-bezier(0.57, 0.26, 1, 0.54);
    animation: first 0.5s 0s ;
}

@keyframes first {
    0% {
        transform: translateX(0vw);
    }
    30% {
        transform: translateX(10vw);
    }
    60% {
        transform: translateX(0vw);
    }
    70% {
        transform: translateX(3px);
    }
    80% {
        transform: translateX(0vw);
    }
}
