@use "../variables";

.button {
    margin: variables.$gutter 0 0;

    @media (min-width: 960px) {
        margin: calc(variables.$gutter * 1.5) 0 0;
    }
}

.error {
    border: 2px solid variables.$error-color;

    &:focus {
        border-color: variables.$error-color;
        box-shadow: 0 0 4px 0 variables.$error-color;
    }
}
