$menu-width: 270px;
$ui-animation-duration: 400ms;
$focus-shadow: 0px 0px 4px 0px #1592E6;
$container-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

$breakpoint-hidden-menu: 1080px;
$gutter: 30px;

$grey-dark: #454F54;
$grey-light: #555E63;

$accent-primary: #929292;
$accent-secondary: #eee;

$warn-color: #FF8B07;
$success-color: #28A745;
$error-color: #C83341;
$info-color: #1276B5;

$body-color: #333;
$heading-color: #555;

// Typography
$base-font-color:  $body-color;
$base-font-family: openSans;
$base-font-size:   16px;
$base-line-height: 1.5625;

// Headings
$heading-font-color:  $heading-color;
$heading-font-family: inherit;
$heading-line-height: 1.333;
$heading-font-weight: 600;

$heading-font-size: 18px;

//Forms
$input-border-active: 2px solid #333333;
$input-border-disabled: 2px solid #EFEFEF;
$input-border-hover: 2px solid #1592E6;
$input-border-error: 2px solid #DC3545;
$input-background-color-disabled: #f3f3f3;
$input-placeholder-color: #757575;
$input-filled-color: #666;
