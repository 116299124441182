.mask {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 101;
    transition: opacity 1s;

    display: flex;
    justify-content: center;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    @media (max-width: 767px) {
        display: unset;
    }
}

.container {
    margin: auto;
}

.card {
    position: relative;
    width: 770px;
    opacity: 1;
    border-radius: 6px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);

    @media (max-width: 767px) {
        width: 100vw;
        min-height: 100vh;
        top: 0px;
        left: 0px;
        transform: unset;
        background-color: white;
    }
}

.header {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: white;
    box-sizing: border-box;
    border-radius: 6px 6px 0 0;
    padding-left: 65px;

    @media (max-width: 767px) {
        width: 100%;
        padding: 40px;
        border-radius: unset;
        box-shadow: unset;
    }
}

.body {
    position: relative;
    background: white;
    box-sizing: border-box;
    border-radius: 0 0 6px 6px;
    padding: 25px 65px;

    @media (max-width: 767px) {
        width: 100%;
        padding: 40px;
        border-radius: unset;
        box-shadow: unset;
    }
}

.bodyText {
    display: flex;
    align-items: center;
}

.headerText {
    font-size: 18.6px;
    margin: 0;
}

.bottomButton {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}

.buttons {
    margin: 0 25px 0 0;
}

.icon {
    padding-top: 16px;
}

.text {
    padding-right: 65px;
}