@use 'src/variables';
@use 'src/scrollbars';

.unread {
    font-weight: 700;
}

.link {
    color: #333;
    text-decoration: none;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.tableContainer {
    font-size: 0.875em;
    overflow-x: scroll;
    padding-bottom: calc(variables.$gutter / 2);

    @include scrollbars.theme-scrollbar;

    @media (min-width: 420px) {
        padding-bottom: variables.$gutter;
    }

    @media (min-width: 680px) {
        overflow-x: auto;
        padding-bottom: 0;
    }

    @media (min-width: 960px) {
        font-size: 1em;
    }
}

.messageTable {
    width: 200vw;

    @media (min-width: 420px) {
        width: 150vw
    }

    @media (min-width: 680px) {
        width: 100%;
    }
}

th.tableHeadings {
    padding: 15px 5px 2px;
}

.cellSubject {
    width: auto;

    @media (min-width: 960px) {
        width: 40%;
    }
}

.cellSentSubject {
    width: auto;

    @media (min-width: 960px) {
        width: 70%;
    }
}

.cellFrom {
    width: auto;

    @media (min-width: 960px) {
        width: 30%;
    }
}

.cellDate {
    width: auto;

    @media (min-width: 960px) {
        width: 30%;
    }
}

.cellAttachment {
    width: auto;
}

.attachmentCell {
    display: flex;
    justify-content: flex-end;
    color: #757575;

    .icon {
        width: 20px;
        height: 24px;
        fill: variables.$accent-primary;
        margin-right: calc(variables.$gutter / 3);
    }
}

.messageRow {
    cursor: pointer;

    :last-child {
        border: none;
    }
}
