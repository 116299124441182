@use "src/variables";

.header {
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #682f91;
    border-radius: 6px 6px 0 0;

    @media (min-width: 600px){
        height: 60px;
    }
}

.arrow {
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;
    margin-top: 13px;

    &.disabled {
        opacity: 0.70;
    }

    &.left {
        left: 20px;
    
        @media (max-width: variables.$breakpoint-hidden-menu) {
            left: 15px;
        }
    }

    &.right {
        transform: scaleX(-1);
        right: 20px;
    
        @media (max-width: variables.$breakpoint-hidden-menu) {
            right: 15px;
        }
    }
    
    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }

    @media (max-width: variables.$breakpoint-hidden-menu) {
        &:hover {
            cursor: pointer;
        }
    }

    @media (min-width: 600px){
        margin-top: 18px;
    }
}
