@use "src/variables";

.container {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #eee;
    cursor: pointer;

    @media (min-width: 600px){
        height: 75px;
        padding: 0 25px;
    }
}

.value, .label {
    line-height: 1.2;
    width: 50%;
}

.value {
    font-size: 17px;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;

    @media (min-width: 600px) {
        font-size: 20px;
    }
}

.label {
    font-size: 14px;
    font-family: variables.$base-font-family;
    color: #757575;
    margin: 0px;
    font-weight: normal;

    @media (min-width: 600px) {
        font-size: 15px;
    }
}

.sectionContainer {
    padding: 30px 0;
}

.listContainer {
    margin: 10px 0px -10px 0px;
    padding: 0px 20px 75px;
    cursor: pointer;

    @media (min-width: 600px){
        margin: 0px;
        padding: 0px 100px 75px;
    }
}

.dropdownTransition {
    transition: height 0.75s ease;
    overflow: hidden;
}

.elementLine {
    border-top: solid 1px #e6e6e6;
    height: 45px;
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;

    align-items: center;

    &:first-child {
        border-top: none;
    }
}

.listKey, .listValue {
    color: #666;
}

.listKey {
    float: left;
    font-size: 13px;
    font-weight: 500;

    @media (min-width: 600px) {
        font-size: 15px;
    }
}

.listValue {
    display: flex;
    float: right;
    font-size: 15px;
    align-content: center;
    align-items: center;

    @media (min-width: 600px) {
        font-size: 17px;
    }
}

.additionsIcon {
    margin-top: 8px;
    fill: #C70000;
    width: 30px;
    height: 30px;
    transition-duration: 0.5s;
    
    &.increase {
        transform: rotate(180deg);
        fill: #47682C;
    }
}

.deductionsIcon {
    margin-top: 8px;
    fill: #47682C;
    width: 30px;
    height: 30px;
    transition-duration: 0.5s;
    
    &.increase {
        transform: rotate(180deg);
        fill: #C70000;
    }
}

.underscoreIcon {
    margin-bottom: 12px;
    fill: #555555;
}

.currentIcon {
    float: left;
    margin-right: 5px;

    @media (min-width: 600px) {
        margin: 2px 4px 0 0;
    }
}
