@use "src/variables";
$graphicWidth: 90.62px;

.cardText {
    color: #757575;
    margin: 0 0 variables.$gutter;
    font-size: 0.875em;
    font-family: variables.$base-font-family;

    @media (min-width: 600px) {
        font-size: 1em;
    }
}

.secondaryContent {
    width: 100%;
}

.tertiaryContent {
    margin-top: 4%;
}

.inputTitle {
    font-size: 16px;
    font-weight: 500;
    color: #757575;
    margin: 2px 0;
}

.input, .error {
    border: 2px solid #878787;
    border-radius: 4px;
    font-size: 14px;
    max-width: 400px;
}

.error {
    border: 2px solid variables.$error-color;

    &:focus {
        border-color: variables.$error-color;
        box-shadow: 0 0 4px 0 variables.$error-color;
    }
}

::placeholder {
    font-size: 13px;
    font-weight: 300;
    font-style: italic;
}

.inputInfo {
    font-size: 13px;
    font-weight: 300;
    color: #757575;
    margin-bottom: 10px;
}

.tertText, .welcomeTertText {
    text-align: left;
    font-size: 13px;
    font-weight: 300;
    color: #757575;
    margin-top: 10px;

    &.hidden {
        display: none;
    }
}

.welcomeTertText {
    @media (min-width: 501px) {
        margin-right: -$graphicWidth;
    }
}

.skipButton {
    float: right;
    margin-top: 10px;
}

.arrowOpen, .arrowClosed {
    position: relative;
    top: 7px;
    right: 0px;
    fill: variables.$accent-primary;
    height: 25px;
    width: 25px;
    transition: transform ease variables.$ui-animation-duration;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.arrowOpen {
    transform: rotate(90deg);
}

.footerButtons {
    margin-top: 20px;
}
