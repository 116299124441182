/*
 * Customising scrolls bar
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width
 * https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
 *
 * Colours may need to be over-ridden in the components
 */

@mixin theme-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(#757575, 0.75) transparent;

    &::-webkit-scrollbar {
        appearance: none;
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(#757575, 0.75);
    }
}
