@use "src/variables";
@import 'node_modules/sassdash/index';
@import 'node_modules/sass-svg/index';

.pageHeader {
    @media (max-width: 600px) {
        height: min-content;
        flex-direction: column;
        justify-content: left;
        align-items: unset;
    }
}

.heading {
    margin-right: variables.$gutter;

    @media (min-width: 600px) {
        margin-right: calc(variables.$gutter * 2);
    }
}

.filterBar {
    margin: 10px 0;
    display: flex;
    flex: 1 1 auto;

    @media (min-width: 600px) {
        margin: 0;
    }
}

.searchBox {
    margin-top: -40px;
    padding-bottom: 20;
    overflow: auto;
    z-index: 1;

    @media (min-width: 600px) {
        margin-top: -70px;
    }
}

.searchBoxContainer {
    margin-bottom: 20px;

    @media (min-width: 600px) {
        margin-top: 0;
    }
}

.animationContainer {
    display: inline-block;
}
