@use "src/variables";

.container {
    display: flex;
    align-items: left;
}

.trusted {
    background-color: variables.$success-color;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    margin-right: 10px;
}
