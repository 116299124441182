@use "../../../variables";

.textInputSmall {
    width: 100%;
    max-width: 200px;
}

.selectInput {
    min-width: 200px;
    max-width: 400px;
    line-height: 25px;
}

.textArea {
    box-sizing: border-box;
    width: 750px;
    max-width: 100%;
    min-height: 200px;
    padding: calc(variables.$gutter / 3);
    resize: vertical;
    white-space: pre-wrap;
    font-size: variables.$base-font-size;
    font-family: variables.$base-font-family;
}

.dateInput {
    max-width: 200px;
    height: 44px;
}
