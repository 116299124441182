.path {
    stroke-dasharray: 900;
    stroke-dashoffset: 0;
    animation: dash 1.2s ease-in-out forwards;
    transform: rotate(-84deg);
    transform-origin: center;
}

@keyframes dash {
    to {
        stroke-dashoffset: 900;
    }
}

.pathReverse {
    stroke-dasharray: 900;
    stroke-dashoffset: 900;
    animation: dashBack 0.8s ease-in-out forwards;
    transform: rotate(-84deg);
    transform-origin: center;

    }
    
@keyframes dashBack {
    to {
        stroke-dashoffset: 0;
    }
}
