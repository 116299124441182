@use "src/variables";

.latestDoc {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 0%;

    > div {
        flex: 1;
        margin-top: 70px;
        width: 100%;

        @media (min-width: 600px) {
            max-width: 370px;
        }
    }
}

.cardActions {
    font-family: variables.$base-font-family;
}
