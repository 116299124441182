@use 'src/variables';

.p45sContainer {
    display: flex;
    flex-wrap: wrap;

    margin: 0 -60px -50px 0;

    > div {
        height: 100%;
        width: 33%;
        box-sizing: border-box;
        border: 0 solid transparent;
        border-width: 0 60px 50px 0;

        > div {
            margin-top: 70px;
            height: 165px;
        }
    }

    @media (max-width: 767px) {
        max-width: 370px;
        margin: 0 auto -50px auto;

        > div {
            width: 100%;
            border-width: 0 0 50px 0;
        }
    }

}

.cardActions {
    font-family: variables.$base-font-family;
}

.displayFullTitle {
    text-wrap: wrap;
}
