.drawer {
    z-index: 99;
    width: auto;
    max-width: 100%;
    min-width: 300px;
    // transition out
    transition: 0.5s ease-in;

    &.visible {
        // transition in
        transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1);
    }

    > button {
        top: 15px;
        left: 25px;
    }

    > div {
        padding: 90px 75px 25px 75px;

        > :first-child {
            margin-bottom: 55px;
        }

        > :last-child {
            margin-top: 55px;
        }

        .buttonContainer {
            top: 25px;
            display: flex;
            flex-direction: row;

            > :not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    .icon {
        color: #858585;
        width: 30px;
        height: 30px;

        &:hover {
            cursor: pointer;
        }

        &.back {
            width: 25px;
            height: 25px;
        }
    }

    .pdfContainer {
        position: relative;
        width: min-content;
        max-width: 100%;
        max-height: calc(100vh - 300px);
        margin: 0px auto;
        border: 1px solid #ccc;
        border-radius: 6px;

        canvas {
            margin: 12.5px;
        }

        &.hidden {
            visibility: hidden;
            overflow: hidden;
            height: 0px;
        }
    }

    &.thin {
        > div {
            padding: 90px 25px 25px 25px;

            > :first-child {
                margin-bottom: 40px;
            }

            > :last-child {
                margin-top: 40px;
            }

            button {
                > span {
                    font-size: 14px;
                }
            }

            .buttonContainer {
                position: absolute;
                top: 16px;
                right: 25px;

                > :not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
    }
}
