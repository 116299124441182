.container {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    line-height: 0.87;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.day {
    font-size: 24px;
    margin-top: -1px;
    font-weight: 400;

    @media(min-width: 600px) {
        font-size: 26px;
        margin-top: -2px;
    }
}

.month, .year {
    font-size: 13px;
    font-weight: 400;

    @media(min-width: 600px) {
        font-size: 14px;
    }
}

.month {
    padding-bottom: 0px;

    @media(min-width: 600px) {
        padding-bottom: 2px;
    }
}

.year {
    padding-top: 0px;

    @media(min-width: 600px) {
        padding-top: 2px;
    }
}
