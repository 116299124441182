@use 'src/variables';

.payslipCover {
    width: auto;
    height: 250px;
    margin: -1px 0 0 0;
    border-radius: 6px;
    box-shadow: none;
    background-color: #dddddd;
    color: #555;

    @media (min-width: 600px) {
        height: 285px;
    }
}

.payslipAlert {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: variables.$base-font-family;
    padding-top: 106px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 38px;

    @media (min-width: 600px) {
        font-size: 28px;
        padding-top: 123.5px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
