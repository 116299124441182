@use 'src/variables';

$iconSizeMobile: 20px;
$iconSizeDesktop: 25px;

.accordion {
    background-color: white;
    border-bottom: solid 1px #e6e6e6;

    &:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }

    &:last-child {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }
}

.header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    border: 0;
    padding: 0;
    background-color: white;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.icon {
    position: absolute;
    right: calc($iconSizeMobile / 2);
    fill: variables.$accent-primary;
    width: $iconSizeMobile;
    height: $iconSizeMobile;
    transition: transform 0.5s;
    top: calc(50% - 10px);

    &.open {
        transform: rotate(90deg);
    }

    @media (min-width: 600px) {
        right: calc($iconSizeDesktop / 2);
        width: $iconSizeDesktop;
        height: $iconSizeDesktop;
        top: calc(50% - 12.5px);
    }
}

.body {
    width: 100%;
    transition: height 0.75s ease;
    overflow: hidden;
}
