@use "src/variables";

.cardText {
    color: #757575;
    margin: 0 0 variables.$gutter;
    font-size: 0.875em;
    font-family: variables.$base-font-family;

    @media (min-width: 600px) {
        font-size: 1em;
    }
}

.secondaryContent {
    width: 100%;
}

.tertiaryContent {
    margin-top: 4%;
}

.tertText, .tertTextHidden {
    opacity: 1;
    text-align: left;
    font-size: 13px;
    font-weight: 300;
    color: #757575;
    margin-top: 2%;
}

.tertTextHidden {
    opacity: 0;
    height: 0;
}

.skipButton {
    float: right;
    margin-top: 10px;
}

.arrowOpen, .arrowClosed {
    position: relative;
    top: 7px;
    right: 0px;
    fill: variables.$accent-primary;
    height: 25px;
    width: 25px;
    transition: transform ease variables.$ui-animation-duration;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.arrowOpen {
    transform: rotate(90deg);
}

.footerButtons {
    margin-top: 20px;
}
