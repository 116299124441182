@use "src/variables";

.contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (min-width: 800px) {
        padding: 0 variables.$gutter;
        box-sizing: border-box;
    }
}

.errorCard {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    max-width: 960px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    min-height: calc(33vh);
}

.cardHeader {
    background: variables.$error-color;
    border-radius: 8px 8px 0 0;
    color: #FFF;
    font-size: 1em;
    display: flex;
    align-items: center;

    @media (min-width: 600px) {
        height: 130px;
        font-size: 1.143em;
    }
}

.cardHeaderInner {
    text-align: left;
    display: flex;
    align-items: center;
    padding: calc(variables.$gutter / 2) variables.$gutter;
}

.icon {
    display: block;
    fill: #FFF;
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;
    margin: 0 variables.$gutter 0 0;
}

.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 100%;
    padding: calc(variables.$gutter / 2);

    @media (min-width: 600px) {
        padding: variables.$gutter;
    }
}

.cardText {
    color: #666;
    margin: 0 0 variables.$gutter;
    font-size: 0.875em;

    @media (min-width: 600px) {
        font-size: 1em;
    }
}

.buttonsContainer {
    display: flex;
    align-items: flex-start;

    @media (max-width: 600px) {
        flex-direction: column;

        > :not(:first-child) {
            margin-top: 15px;
            // margin-left: 0px; will remove the default non-zero margin of subsequent DefaultButton components
            margin-left: 0px;
        }
    }
}
