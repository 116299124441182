@use "src/variables";

.pageHeader {
    @media (max-width: 600px) {
        height: min-content;
        flex-direction: column;
        justify-content: left;
        align-items: unset;
    }
}

.heading {
    margin-right: variables.$gutter;

    @media (min-width: 600px) {
        margin-right: calc(variables.$gutter * 2);
    }
}

.filterBar {
    margin: 10px 0;
    display: flex;
    flex: 1 1 auto;

    @media (min-width: 600px) {
        margin: 0;
    }
}

.notificationsContainer {
    > :not(:last-child) {
        margin-bottom: 25px;
    }
}