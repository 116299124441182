@use "src/variables";

.headerWrapper {
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    &.boxedHeader {
        margin-bottom: 15px;
    }

    @media (min-width: 600px) {
        height: 25px;
        margin-bottom: 35px;
    }
}

.title {
    margin: 0px;
    font-size: 16px;

    @media (min-width: 600px) {
        font-size: variables.$heading-font-size;
    }
}
