@use '../../variables';

.textArea {
    box-sizing: border-box;
    width: 600px;
    max-width: 100%;
    min-height: 180px;
    padding: calc(variables.$gutter / 2);
    resize: vertical;
    white-space: pre-wrap;
    line-height: 1.875;
}

.buttonContainer {
    margin: variables.$gutter 0 0;

    @media (min-width: 960px) {
        margin: calc(variables.$gutter * 1.5) 0 0;
    }
}

.primaryButtonContainer,
.secondaryButtonContainer {
    font-size: 0.875em;
    display: inline-block;

    @media (min-width: 960px) {
        font-size: 1em;
    }
}

.secondaryButtonContainer {
    margin: calc(variables.$gutter / 2) 0 0 0;

    @media (min-width: 600px) {
        margin: 0 0 0 variables.$gutter;
    }
}

.error {
    border: 1px solid variables.$error-color;

    &:focus {
        border-color: variables.$error-color;
        box-shadow: 0 0 4px 0 variables.$error-color;
    }
}
