@use '../../../variables';

.messageContent {
    font-size: 0.75em;

    @media (min-width: 600px) {
        font-size: 0.875em;
    }

    @media (min-width: 960px) {
        font-size: 1em;
    }
}

.fromContainer {
    margin-bottom: variables.$gutter;
}

.messageContainer {
    margin-bottom: calc(variables.$gutter * 2);
}

.sectionTitle {
    text-transform: uppercase;
    font-weight: 700;
    color: #757575;
    margin-bottom: calc(variables.$gutter / 4);
}

.messageBody {
    white-space: pre-wrap;
    line-height: 1.875;
}

.buttonContainer {
    margin: variables.$gutter 0 0;

    @media (min-width: 960px) {
        margin: calc(variables.$gutter * 1.5) 0 0;
    }
}

.primaryButtonContainer,
.secondaryButtonContainer {
    font-size: 0.875em;
    display: inline-block;

    @media (min-width: 960px) {
        font-size: 1em;
    }
}

.secondaryButtonContainer {
    margin: calc(variables.$gutter / 2) 0 0 0;

    @media (min-width: 600px) {
        margin: 0 0 0 variables.$gutter;
    }
}

.replyTextArea {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    min-height: 40vh;
    padding: calc(variables.$gutter / 2);
    resize: vertical;
    white-space: pre-wrap;
    line-height: 1.875;
}

.attachmentSection {
    display: flex;
    flex-wrap: wrap;
    margin-top: variables.$gutter;

    @media (min-width: 960px) {
        margin-top: calc(variables.$gutter * 2);
    }

    .attachment {
        display: flex;
        align-items: center;
        color: #757575;
        font-size: 0.75em;
        margin: 0 variables.$gutter 0 0;
        padding: calc(variables.$gutter / 3) 0;

        @media (min-width: 600px) {
            padding: calc(variables.$gutter / 6) 0;
        }

        @media (min-width: 960px) {
            padding: calc(variables.$gutter / 3) 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .downloadButton {
        background: none;
        border: none;
        padding: 0;
        color: #757575;
        cursor: pointer;

        :global(.__flag-user-is-not-tabbing) &:focus {
            outline: none;
        }
    }

    .icon {
        width: 20px;
        height: 24px;
        fill: variables.$accent-primary;
        margin-right: calc(variables.$gutter / 4);
    }
}
