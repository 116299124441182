.card {
    position: relative;
    width: 770px;
    opacity: 1;
    border-radius: 6px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);

    @media (max-width: 767px) {
        width: 100vw;
        min-height: 100vh;
        top: 0px;
        left: 0px;
        transform: unset;
        background-color: white;
    }
}

.header {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: white;
    box-sizing: border-box;
    border-radius: 6px 6px 0 0;
    padding-left: 65px;
    font-size: 22px;
    font-weight: 700;
    border-bottom: solid 1px #e6e6e6;

    @media (max-width: 767px) {
        width: 100%;
        padding: 40px;
        border-radius: unset;
        box-shadow: unset;
        font-size: 17px;
        font-weight: 700;
        padding-right: 0px;
    }
}

.body {
    position: relative;
    background: white;
    box-sizing: border-box;
    border-radius: 0 0 6px 6px;
    padding: 65px;

    @media (max-width: 767px) {
        width: 100%;
        padding: 40px;
        border-radius: unset;
        box-shadow: unset;
    }
}

.appleBadge {
    margin-top: 20px;
    width: 160px;
    height: 60px;
}

.androidBadge {
    margin-top: 12px;
    width: 180px;
    height: 76px;
}

.qrCode {
    width: 100px;
    height: 100px;
    margin: 0 20px;
}

.badgesContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
}

.badgeQRContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-right: 30px;
}

.textContainer {
    display: flex;
}

.container {
    margin: auto;
}

.headerText {
    font-size: 18.6px;
    margin: 0;
}

.logoutButton {
    position: absolute;
    bottom: 65px;
    right: 65px;
}

.bottomButton, .bottomButtonNoMargin {
    display: flex;
    flex-direction: row;
}

.bottomButton {
    margin: -38px 0 0 160px;
}

.bottomButtonNoMargin {
    margin-top: -38px;
}

.bodyText {
    margin-bottom: 30px;
}
