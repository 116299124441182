@use "../variables";

.container {
    position: absolute;
    bottom: 20px;
    left: 50%;
}

.poweredByGraphic {
    transform: translateX(-50%);
    width: 100px;
    
    @media (min-width: variables.$breakpoint-hidden-menu) {
        margin-left: calc(variables.$menu-width / 2);
    }
}
