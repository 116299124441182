.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fadeOut {
    opacity: 0;
}

.loginForm {
    width: 360px;

    @media (min-width: 600px) {
        height: 70%;
    }
}

.loadingSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
