@use "../../variables";

.bulkHeader {
    margin: 0;
    width:100%;
    display:flex;
    justify-content: space-between;
}

.pageTitle {
    color: #555;
    font-size: 16px;
    text-align: center;
    margin: auto 0;

    @media (min-width: 600px) {
        font-size: 18px;
    }
}

.pageContent {
    display: flex;
    height:100px;
}

.yearSelect {
    cursor: pointer;
    border-radius: 4px;
    width: 150px;
}

.button {
    position: relative;
    float: left;

    display: flex;
    align-items: center;

    background: none;
    border: none;
    cursor: pointer;

    text-transform: uppercase;
    font-weight: 700;
    color: #666;

    @media (max-width: 600px) {
        font-size: 14px;
    }

    .icon {
        display: none;
        fill: #bbb;

        @media (min-width: 600px) {
            display: inline;
            margin-left: 8px;
        }
    }

    &:hover {
        .underlineFill {
            width: 100%;
        }
    }

    .text {
        font-size: 1em;
        font-family: variables.$base-font-family;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 2px;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.closeButton {
    margin: 25px 0 0 0;
    padding: 0 0 20px 0;
}
