@use "src/variables";
@use "src/helpers";
@use 'src/scrollbars';

.container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: variables.$menu-width;
    height: 100%;
    background: #fff;
    transition: transform ease variables.$ui-animation-duration;
    z-index: 99;

    @media (max-width: #{variables.$breakpoint-hidden-menu - 1}) {
        $shadow-length: 20px;

        &::after {
            display: block;
            position: absolute;
            content: "";
            width: $shadow-length;
            height: 100%;
            top: 0px;
            right: -$shadow-length;
            background: linear-gradient(
                to right,
                rgba(0, 0,  0, 0.15),
                rgba(0, 0, 0, 0)
            );
        }

        transform: translateX(-100%) translateX(-$shadow-length);

        &.open {
            transform: translateX(0%);
        }
    }
}

.menuLoaded {
    @media (min-width: variables.$breakpoint-hidden-menu) {
        animation-delay: 2300ms;
        animation-duration: 0.4s;
        transform: translateX(-100%);

        @include helpers.keyframe-animation((
            ( delay: 0ms, styles: ( transform: translateX(-100%) ) ),
            ( delay: 300ms, styles: ( transform: translateX(0%) ) ),
        ));
    }
}

.logoLink {
    display: block;
    margin-top: variables.$gutter;

    @media (max-width: #{variables.$breakpoint-hidden-menu - 1}) {
        margin-top: calc(variables.$gutter + 20px);
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.logo {
    padding: variables.$gutter - 5px;
    width: calc(100% - #{variables.$gutter * 2});
}

.menuContainer {
    margin: variables.$gutter - 5px;
}

.menuList {
    list-style: none;
    margin: 0px;
    padding: 0px;
    overflow-y: auto;

    @include scrollbars.theme-scrollbar;
}
